import { ampContract } from '@iheartradio/web.api/amp';
import { getServerTiming } from '@iheartradio/web.server-timing';
import { type LoaderFunctionArgs, json } from '@remix-run/server-runtime';
import { prop } from 'remeda';
import { z } from 'zod';

import { hydrateContext } from '~app/utilities/context.server';
import { notAuthorized, requireRegisteredUser } from '~app/utilities/http';

import { PlaylistTypes } from './_app/playlist.$playlistSlug/constants';

export const getCollectionsParamsSchema =
  ampContract.v3.collection.getCollections.query;

export type ListCollectionsLoader = typeof loader;

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const { time, getServerTimingHeader } = getServerTiming({
    prefix: 'list_collections',
  });

  try {
    const { amp } = hydrateContext(context);
    const user = await requireRegisteredUser(request);

    if (!user) {
      throw notAuthorized();
    }

    const url = new URL(request.url);
    const searchParams = url.searchParams;

    const collections = await time('getCollections', () =>
      amp.api.v3.collection.getCollections({
        params: { userId: user.profileId },
        query: getCollectionsParamsSchema.parse(
          Object.fromEntries(searchParams),
        ),
      }),
    ).then(prop('body'));

    // `new4u` is "Weekly Playlist" which is not a writable collection, therefore it should be
    // excluded from this list. The default for the `includePersonalized` query param for the
    // `getCollections` endpoint is `false`, but `new4u` predates the other personalized playlists
    // before it was called personalized playlists. That flag was introduced to allow backward
    // compatibility w/ clients that didn’t support the newer personalized playlists types. `new4u`
    // id is the same for all users
    collections.data = collections.data.filter(
      collection => collection.id !== PlaylistTypes.New4U,
    );

    return json({ error: null, data: collections, ok: true } as const, {
      headers: { ...getServerTimingHeader() },
    });
  } catch (error: unknown) {
    let errorMessage = 'An unknown error occurred.';

    if (error instanceof Response) {
      throw error;
    } else if (error instanceof z.ZodError) {
      errorMessage = 'Invalid params';
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    return json({ errorMessage, data: null, ok: false } as const);
  }
};

import {
  AddIcon,
  Box,
  Flex,
  LoadingIcon,
  Menu,
} from '@iheartradio/web.companion';
import {
  isFunction,
  isNil,
  removeNilValuesFromObject,
  toFormData,
} from '@iheartradio/web.utilities';
import { useFetcher } from '@remix-run/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { route } from 'routes-gen';

import { type PlaylistCollection } from '~app/api/types';
import {
  type AddToCollectionAction,
  type AddToCollectionActionData,
} from '~app/routes/api.v1.collection.add';
import {
  type ListCollectionsLoader,
  getCollectionsParamsSchema,
} from '~app/routes/api.v1.collection.list';
import { isDoneFetching } from '~app/utilities/fetcher';

import {
  type CreatePlaylistSubmitCallback,
  CreatePlaylistDialog,
} from '../dialogs/create-playlist-dialog';

export type AddToCollectionSubmitCallback = (
  playlist?: AddToCollectionActionData,
) => void;

export type AddToPlaylistMenuItemsProps = {
  tracks?: number[];
  albumId?: number;
  triggerText?: string;
  onAddSubmit?: AddToCollectionSubmitCallback;
  onCreateSubmit: CreatePlaylistSubmitCallback;
};

type AddToPlaylistMenuRow =
  | { type: 'create-playlist' }
  | { type: 'playlist'; data: PlaylistCollection };

const playlistLibraryRoute = route('/api/v1/collection/list');

export const AddToPlaylistSubMenu = (props: AddToPlaylistMenuItemsProps) => {
  const {
    tracks,
    albumId,
    onAddSubmit,
    onCreateSubmit,
    triggerText = 'Add to playlist',
  } = props;
  const [playlists, setPlaylists] = useState<Array<PlaylistCollection>>([]);

  const addToCollectionFetcher = useFetcher<AddToCollectionAction>();
  const playlistsFetcher = useFetcher<ListCollectionsLoader>();

  let rows: Array<AddToPlaylistMenuRow> = playlists.map(playlist => ({
    type: 'playlist',
    data: playlist,
  }));
  rows = [{ type: 'create-playlist' }, ...rows];

  const parentRef = useRef<HTMLDivElement | null>(null);

  const nextPageKey = playlistsFetcher?.data?.data?.links?.nextPageKey;

  const isLoadingMoreResults = playlistsFetcher.state === 'loading';

  // Assume we have a next page if we haven't fetched any data. Otherwise, look for a "nextPageKey"
  const hasNextPage = isNil(playlistsFetcher?.data)
    ? true
    : playlistsFetcher?.data?.ok && Boolean(nextPageKey);

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? rows.length + 1 : rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 5,
  });

  const items = rowVirtualizer.getVirtualItems();

  const fetchMore = useCallback(() => {
    if (playlistsFetcher.state === 'idle' && hasNextPage) {
      const searchParams = new URLSearchParams(
        getCollectionsParamsSchema.transform(removeNilValuesFromObject).parse({
          pageKey: nextPageKey,
          playlistFilter: 'created',
        }),
      );
      playlistsFetcher.load(`${playlistLibraryRoute}?${searchParams}`);
    }
  }, [hasNextPage, nextPageKey, playlistsFetcher]);

  useEffect(() => {
    const newlyFetchedPlaylists = playlistsFetcher?.data?.data?.data ?? [];

    if (isDoneFetching(playlistsFetcher)) {
      setPlaylists(existingPlaylists => [
        ...existingPlaylists,
        ...newlyFetchedPlaylists,
      ]);
    }
  }, [playlistsFetcher]);

  useEffect(() => {
    const lastItem = items.at(-1);

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasNextPage) {
      fetchMore();
    }
  }, [hasNextPage, fetchMore, isLoadingMoreResults, rows.length, items]);

  useEffect(() => {
    if (isDoneFetching(addToCollectionFetcher) && isFunction(onAddSubmit)) {
      onAddSubmit(addToCollectionFetcher?.data);
    }
  }, [addToCollectionFetcher, onAddSubmit]);

  return (
    <Menu.Sub
      data-test="add-to-playlist-sub-menu"
      onOpenChange={open => {
        if (open) {
          fetchMore();
          rowVirtualizer.measure();
        }
      }}
      ref={parentRef}
      trigger={triggerText}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {items.map(virtualRow => {
          const isLoaderRow = virtualRow.index > rows.length - 1;
          const row = rows.at(virtualRow.index);

          return (
            <Box
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
              data-index={virtualRow.index}
              key={virtualRow.key}
            >
              {isLoaderRow ? (
                <Menu.Item>
                  <Flex
                    css={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <LoadingIcon css={{ marginLeft: 'unset' }} size="24" />
                  </Flex>
                </Menu.Item>
              ) : !row ? null : row.type === 'create-playlist' ? (
                <CreatePlaylistDialog
                  albumId={albumId}
                  onSubmit={onCreateSubmit}
                  tracks={tracks}
                  trigger={
                    <Menu.Item
                      data-test="create-playlist-menu-item"
                      onSelect={event => {
                        // This ensures that clicking the MenuItem doesn't close the Menu and only opens the Dialog
                        event?.preventDefault();
                      }}
                    >
                      <Menu.Item.LeftSlot>
                        <AddIcon />
                      </Menu.Item.LeftSlot>
                      Create new playlist
                    </Menu.Item>
                  }
                />
              ) : row.type === 'playlist' ? (
                <Menu.Item
                  onSelect={event => {
                    // This ensures that clicking the MenuItem doesn't close the Menu and only opens the Dialog
                    event?.preventDefault();

                    const namedAction = tracks?.length ? 'byTracks' : 'byAlbum';

                    addToCollectionFetcher.submit(
                      toFormData({
                        tracks,
                        collectionId: row.data.id,
                        intent: namedAction,
                        albumId,
                      }),
                      {
                        method: 'POST',
                        action: route('/api/v1/collection/add'),
                      },
                    );
                  }}
                >
                  {row.data.name}
                </Menu.Item>
              ) : null}
            </Box>
          );
        })}
      </div>
    </Menu.Sub>
  );
};
